'use strict';

if (![].includes) {
  Array.prototype.includes = function(searchElement/*, fromIndex*/) {
    var O = Object(this);
    var len = parseInt(O.length) || 0;
    if (len === 0) {
      return false;
    }
    var n = parseInt(arguments[1]) || 0;
    var k;
    if (n >= 0) {
      k = n;
    } else {
      k = len + n;
      if (k < 0) {
        k = 0;
      }
    }
    while (k < len) {
      var currentElement = O[k];
      if (searchElement === currentElement ||
         (searchElement !== searchElement && currentElement !== currentElement)
      ) {
        return true;
      }
      k++;
    }
    return false;
  };
}

if (!Array.isArray) {
  Array.isArray = function(arg) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  };
}

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position){
      position = position || 0;
      return this.substr(position, searchString.length) === searchString;
  };
}

/** Класс, представляющий собой базовую обертку форм ввода. */
class CCottonClubCore {
    /**
     * Конструктор.
     */
    constructor() {
        /*
         * Массив для ошибок 
         */
        this.arErrors = []; 
        /*
         * Имя сервиса потомка
         */
        this.serviceName = ""; 
        /*
         * Имя сервиса потомка
         */
        this.executeTime = 0; 

        this.init();

    }

    init() {

        this.executeTime = - this.microtime(true);

        try {
            this.execute();
        } catch(e) {
            

        } 

        this.executeTime +=  this.microtime(true);
        this.createLog();


        
    }


    /**
     * Абстрактный метод, который должен быть реализован в потомках
     */
    execute(){
        throw new Error("Method must be implemented");
    }

    microtime(getAsFloat) {
        var s, now, multiplier;

        if(typeof performance !== 'undefined' && performance.now) {
            now = (performance.now() + performance.timing.navigationStart) / 1000;
            multiplier = 1e6; // 1,000,000 for microseconds
        }
        else {
            now = (Date.now ? Date.now() : new Date().getTime()) / 1000;
            multiplier = 1e3; // 1,000
        }

        // Getting microtime as a float is easy
        if(getAsFloat) {
            return now;
        }

        // Dirty trick to only get the integer part
        s = now | 0;

        return (Math.round((now - s) * multiplier ) / multiplier ) + ' ' + s;
    }
    createLog(){

    }
}

class CCottonClubSlider extends CCottonClubCore {

    constructor() {
        super();
    }

    sliderInit(cssIdBlockOne,cssIdBlockTwo, cssDataName,cssClassLeftArrow,cssClassRigthArrow,slideByIntervalTime, animationOut,animationIn,animationDelay,animationInTwo,animationOutTwo, csslink = 'none', backgroundImage = 'none' ) {
        
        let direction = "right";     
        var arDataBrand = $(cssIdBlockOne).data(cssDataName);
        var arDataBrandTwo = 'none';
        var self = this;
        if (cssIdBlockTwo!= 'none') {
            arDataBrandTwo = $(cssIdBlockTwo).data(cssDataName);
        }
        if(csslink != "none"){
            var arDataLink = $(csslink).data(cssDataName);
        }
        if(backgroundImage != "none"){
            var arBackgroundImage = $(".b-header-left__item-textblock__two").data("background-class");
        }
        var arDataBrandIndex = 0;
        this.clickSliderButton(cssIdBlockOne,cssIdBlockTwo, cssDataName, cssClassRigthArrow, direction, arDataBrand, arDataBrandTwo, arDataBrandIndex, animationOut,animationIn,animationDelay,animationInTwo,animationOutTwo, csslink, arDataLink, backgroundImage, arBackgroundImage);
        direction = "left";
        this.clickSliderButton(cssIdBlockOne,cssIdBlockTwo, cssDataName, cssClassLeftArrow, direction, arDataBrand, arDataBrandTwo, arDataBrandIndex, animationOut,animationIn,animationDelay,animationInTwo,animationOutTwo, csslink, arDataLink, backgroundImage, arBackgroundImage);
        if (slideByIntervalTime!='none') {
            this.slideByInterval(cssIdBlockOne,cssIdBlockTwo, cssDataName, cssClassLeftArrow,cssClassRigthArrow, arDataBrand, arDataBrandTwo, arDataBrandIndex,slideByIntervalTime, animationOut,animationIn,animationDelay,animationInTwo,animationOutTwo,self, csslink, arDataLink, backgroundImage, arBackgroundImage);
        }
        
        $(cssIdBlockOne).text(arDataBrand[arDataBrandIndex]);
        $(cssIdBlockOne).swipe({
            swipeLeft:function(event,direction,distance, duration,fingerCount){
                if (fingerCount!=0) {
                    if (arDataBrandIndex==0) {
                        arDataBrandIndex = arDataBrand.length;
                    }
                    arDataBrandIndex = arDataBrandIndex - 1;

                    if (csslink !='none') {
                        $(csslink).attr('href', arDataLink[arDataBrandIndex]);
                    }

                    if (backgroundImage !='none') {
                        $(".b-header").addClass(arBackgroundImage[arDataBrandIndex]);

                        arBackgroundImage.forEach(function(entry) {
                            if(entry != arBackgroundImage[arDataBrandIndex]){
                                $(".b-header").removeClass(entry);
                            }
                        });
                    }
                    if ($(cssIdBlockOne).hasClass('animated')) {
                        $(cssIdBlockOne).removeClass(animationIn);
                        $(cssIdBlockOne).addClass(animationOut);
                        setTimeout(function() {
                            $(cssIdBlockOne).text(arDataBrand[arDataBrandIndex]);
                            $(cssIdBlockOne).removeClass(animationOut);
                            $(cssIdBlockOne).addClass(animationIn);
                        },animationDelay);
                        if (cssIdBlockTwo!='none') {
                            $(cssIdBlockTwo).removeClass(animationInTwo);
                            $(cssIdBlockTwo).addClass(animationOutTwo);
                            setTimeout(function() {
                                $(cssIdBlockTwo).text(arDataBrandTwo[arDataBrandIndex]);
                                $(cssIdBlockTwo).removeClass(animationOutTwo);
                                $(cssIdBlockTwo).addClass(animationInTwo);
                            },animationDelay);
                        }
                    }
                    else{
    		            $(cssIdBlockOne).fadeOut(function(){ 
    		                $(cssIdBlockOne).text(arDataBrand[arDataBrandIndex]);
    		                $(cssIdBlockOne).fadeIn();

    			        });
                    }
                }
            },
            swipeRight:function(event,direction,distance, duration,fingerCount){
                if (fingerCount!=0) {
                    arDataBrandIndex +=1;
                    if (arDataBrandIndex==arDataBrand.length) {
                        arDataBrandIndex = 0;
                    }

                    if (csslink !='none') {
                        $(csslink).attr('href', arDataLink[arDataBrandIndex]);
                    }
                    if (backgroundImage !='none') {
                        $(".b-header").addClass(arBackgroundImage[arDataBrandIndex]);
                        arBackgroundImage.forEach(function(entry) {
                            if(entry != arBackgroundImage[arDataBrandIndex]){
                                $(".b-header").removeClass(entry);
                            }
                        });
                    }
                    if ($(cssIdBlockOne).hasClass('animated')) {
                        $(cssIdBlockOne).removeClass(animationIn);
                        $(cssIdBlockOne).addClass(animationOut);
                        setTimeout(function() {
                            $(cssIdBlockOne).text(arDataBrand[arDataBrandIndex]);
                            $(cssIdBlockOne).removeClass(animationOut);
                            $(cssIdBlockOne).addClass(animationIn);
                        },animationDelay);
                        if (cssIdBlockTwo!='none') {
                            $(cssIdBlockTwo).removeClass(animationInTwo);
                            $(cssIdBlockTwo).addClass(animationOutTwo);
                            setTimeout(function() {
                                $(cssIdBlockTwo).text(arDataBrandTwo[arDataBrandIndex]);
                                $(cssIdBlockTwo).removeClass(animationOutTwo);
                                $(cssIdBlockTwo).addClass(animationInTwo);
                            },animationDelay);
                        }
                    }
                    else{
                        $(cssIdBlockOne).fadeOut(function(){ 
                            $(cssIdBlockOne).text(arDataBrand[arDataBrandIndex]);
                            $(cssIdBlockOne).fadeIn();

                        });
                    }
                }
            }
        });
    }

    clickSliderButton(cssIdBlockOne,cssIdBlockTwo, cssDataName, cssClassArrow, direction, arDataBrand, arDataBrandTwo, arDataBrandIndex, animationOut,animationIn,animationDelay,animationInTwo,animationOutTwo, csslink = "none", arDataLink = "none", backgroundImage = 'none', arBackgroundImage = "none"){

        $(cssClassArrow).click(function() {
            if(direction == "right"){
                arDataBrandIndex +=1;
                if (arDataBrandIndex==arDataBrand.length) {
                    arDataBrandIndex = 0;
                }
            }

            if(direction == "left"){
                if (arDataBrandIndex==0) {
                    arDataBrandIndex = arDataBrand.length;
                }
                arDataBrandIndex = arDataBrandIndex - 1;
            }

            if (csslink !='none') {
                $(csslink).attr('href', arDataLink[arDataBrandIndex]);
            }

            if (backgroundImage !='none') {
                $(".b-header").addClass(arBackgroundImage[arDataBrandIndex]);
                arBackgroundImage.forEach(function(entry) {
                    if(entry != arBackgroundImage[arDataBrandIndex]){
                        $(".b-header").removeClass(entry);
                    }
                });
            }

            if ($(cssIdBlockOne).hasClass('animated')) {
                $(cssIdBlockOne).removeClass(animationIn);
                $(cssIdBlockOne).addClass(animationOut);
                setTimeout(function() {
                    $(cssIdBlockOne).text(arDataBrand[arDataBrandIndex]);
                    $(cssIdBlockOne).removeClass(animationOut);
                    $(cssIdBlockOne).addClass(animationIn);
                },animationDelay);
                if (cssIdBlockTwo!='none') {
                    $(cssIdBlockTwo).removeClass(animationInTwo);
                    $(cssIdBlockTwo).addClass(animationOutTwo);
                    setTimeout(function() {
                        $(cssIdBlockTwo).text(arDataBrandTwo[arDataBrandIndex]);
                        $(cssIdBlockTwo).removeClass(animationOutTwo);
                        $(cssIdBlockTwo).addClass(animationInTwo);
                    },animationDelay);
                }

            }
            else{
                $(cssIdBlockOne).fadeOut(function(){ 
                    $(cssIdBlockOne).text(arDataBrand[arDataBrandIndex]);
                    $(cssIdBlockOne).fadeIn();
                      
                });
            }

        });

    }
    slideByInterval(cssIdBlockOne,cssIdBlockTwo, cssDataName, cssClassLeftArrow,cssClassRigthArrow, arDataBrand, arDataBrandTwo, arDataBrandIndex,slideByIntervalTime, animationOut,animationIn,animationDelay,animationInTwo,animationOutTwo,self='none', csslink = "none", arDataLink = "none", backgroundImage = 'none', arBackgroundImage = "none"){
        setInterval(function(){
            if (self.checkArrow(cssClassLeftArrow,cssClassRigthArrow)===true) {
                arDataBrandIndex+=1;
                if (arDataBrandIndex==arDataBrand.length) {
                        arDataBrandIndex = 0;
                }

                if (csslink !='none') {
                    $(csslink).attr('href', arDataLink[arDataBrandIndex]);
                }


                if (backgroundImage !='none') {
                    $(".b-header").addClass(arBackgroundImage[arDataBrandIndex]);
                    arBackgroundImage.forEach(function(entry) {
                        if(entry != arBackgroundImage[arDataBrandIndex]){
                            $(".b-header").removeClass(entry);
                        }
                    });
                }

                if ($(cssIdBlockOne).hasClass('animated')) {
                    $(cssIdBlockOne).removeClass(animationIn);
                    $(cssIdBlockOne).addClass(animationOut);
                    setTimeout(function() {
                        $(cssIdBlockOne).text(arDataBrand[arDataBrandIndex]);
                        $(cssIdBlockOne).removeClass(animationOut);
                        $(cssIdBlockOne).addClass(animationIn);
                    },animationDelay);
                    if (cssIdBlockTwo!='none') {
                        $(cssIdBlockTwo).removeClass(animationInTwo);
                        $(cssIdBlockTwo).addClass(animationOutTwo);
                        setTimeout(function() {
                            $(cssIdBlockTwo).text(arDataBrandTwo[arDataBrandIndex]);
                            $(cssIdBlockTwo).removeClass(animationOutTwo);
                            $(cssIdBlockTwo).addClass(animationInTwo);
                        },animationDelay);
                    }
                }
                else{
                    $(cssIdBlockOne).fadeOut(function(){ 
                        $(cssIdBlockOne).text(arDataBrand[arDataBrandIndex]);
                        $(cssIdBlockOne).fadeIn();
                          
                    });
                }
            }
        },slideByIntervalTime);
    }
    checkArrow(cssClassLeftArrow,cssClassRightArrow){
        if (cssClassLeftArrow == "none") {
            if ($(cssClassRightArrow).is(':hover')===false) {
                return true;
            }
            else{
                return false;
            }
        }
        else if (cssClassRightArrow  == "none"){
            if ($(cssClassLeftArrow).is(':hover')===false) {
                return true;
            }
            else{
                return false;
            }
        }
        else{
            if ($(cssClassLeftArrow).is(':hover')===false && $(cssClassRightArrow).is(':hover')===false) {
                return true;
            }
            else{
                return false;
            }
        }
    }
}

class CCottonClubSliderMainPageBrands extends CCottonClubSlider {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        $(function() {
            $('.owl-carousel').owlCarousel({
                items: 1,
                itemsDesktop: false,
                itemsDesktopSmall: false,
                itemsTablet: false,
                itemsTabletSmall: false,
                itemsMobile: false,
                autoHeight: true,
                loop:true,
                slideBy:1,
                nav:false,
                autoplay:true,
                autoplayTimeout:10000,
                autoplayHoverPause: true,
                responsive:{
                    1:{
                        items:1
                    },
                    320:{
                        items:2
                    },
                    768:{
                        items:4
                    },
                    1024:{
                        items:5
                    },
                    1920:{
                        items:6
                    }
                } 
            });
        });
    }
}

class CCottonClubSliderMainPageOurBrands extends CCottonClubSlider {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        let cssIdBlockOne = "#b-content-row-textblock";
        let cssIdBlockTwo = 'none';
        let cssDataName = "brand";
        let cssClassLeftArrow = ".left-arrow";
        let cssClassRigthArrow = ".right-arrow";
        let slideByIntervalTime = 10000;

        this.sliderInit(cssIdBlockOne, cssIdBlockTwo ,cssDataName,cssClassLeftArrow,cssClassRigthArrow,slideByIntervalTime);
    }
}

class CCottonClubSliderMainPageProducts extends CCottonClubCore {

    constructor() {
        super();
    }

    sliderInit(cssIdBlockMain,cssIdBlockTop,cssIdBlockBottom,cssIdBlockTopOne,cssIdBlockTopTwo,cssIdBlockBottomOne,cssIdBlockBottomTwo,cssDataName,cssClassLeftArrow,cssClassRigthArrow,animationOut,animationIn,animationDelay,animationOutTwo,animationInTwo) {
        let direction = "right";     
        var arDataBrandTopOne = $(cssIdBlockTopOne).data(cssDataName);
        var arDataBrandTopTwo = $(cssIdBlockTopTwo).data(cssDataName);
        var arDataBrandBottomOne = $(cssIdBlockBottomOne).data(cssDataName);
        var arDataBrandBottomTwo = $(cssIdBlockBottomTwo).data(cssDataName);
        var arDataBrandIndex = 0;
        this.clickSliderButton(cssIdBlockMain,cssIdBlockTop,cssIdBlockBottom,cssIdBlockTopOne,cssIdBlockTopTwo,cssIdBlockBottomOne,cssIdBlockBottomTwo, cssDataName, cssClassRigthArrow, direction, arDataBrandTopOne, arDataBrandTopTwo,arDataBrandBottomOne,arDataBrandBottomTwo, arDataBrandIndex,animationOut,animationIn,animationDelay,animationOutTwo,animationInTwo);
        direction = "left";
        this.clickSliderButton(cssIdBlockMain,cssIdBlockTop,cssIdBlockBottom,cssIdBlockTopOne,cssIdBlockTopTwo,cssIdBlockBottomOne,cssIdBlockBottomTwo, cssDataName, cssClassLeftArrow, direction, arDataBrandTopOne, arDataBrandTopTwo,arDataBrandBottomOne,arDataBrandBottomTwo, arDataBrandIndex,animationOut,animationIn,animationDelay,animationOutTwo,animationInTwo);
        

        if ($(cssIdBlockTopOne).find('.b-products__img').prop('tagName')=="IMG") {
            $(cssIdBlockTopOne).find('.b-products__img').attr('src',arDataBrandTopOne[arDataBrandIndex][0]);
        }else{
            $(cssIdBlockTopOne).find('.b-products__img').text(arDataBrandTopOne[arDataBrandIndex][0]);
        }
        
        $(cssIdBlockTopOne).find('.b-products__value').text(arDataBrandTopOne[arDataBrandIndex][1]);
        $(cssIdBlockTopOne).find('.b-products__name').text(arDataBrandTopOne[arDataBrandIndex][2]);
        $(cssIdBlockTopOne).find('.js-products-url').attr('href',arDataBrandTopOne[arDataBrandIndex][3]);


        if ($(cssIdBlockTopTwo).find('.b-products__img').prop('tagName')=="IMG") {
            $(cssIdBlockTopTwo).find('.b-products__img').attr('src',arDataBrandTopTwo[arDataBrandIndex][0]);
        }else{
            $(cssIdBlockTopTwo).find('.b-products__img').text(arDataBrandTopTwo[arDataBrandIndex][0]);
        }

        $(cssIdBlockTopTwo).find('.b-products__value').text(arDataBrandTopTwo[arDataBrandIndex][1]);
        $(cssIdBlockTopTwo).find('.b-products__name').text(arDataBrandTopTwo[arDataBrandIndex][2]);
        $(cssIdBlockTopTwo).find('.js-products-url').attr('href',arDataBrandTopTwo[arDataBrandIndex][3]);

        if ($(cssIdBlockBottomOne).find('.b-products__img').prop('tagName')=="IMG") {
            $(cssIdBlockBottomOne).find('.b-products__img').attr('src',arDataBrandBottomOne[arDataBrandIndex][0]);
        }else{
            $(cssIdBlockBottomOne).find('.b-products__img').text(arDataBrandBottomOne[arDataBrandIndex][0]);
        }
        
        $(cssIdBlockBottomOne).find('.b-products__value').text(arDataBrandBottomOne[arDataBrandIndex][1]);
        $(cssIdBlockBottomOne).find('.b-products__name').text(arDataBrandBottomOne[arDataBrandIndex][2]);
        $(cssIdBlockBottomOne).find('.js-products-url').attr('href',arDataBrandBottomOne[arDataBrandIndex][3]);


        if ($(cssIdBlockBottomTwo).find('.b-products__img').prop('tagName')=="IMG") {
            $(cssIdBlockBottomTwo).find('.b-products__img').attr('src',arDataBrandBottomTwo[arDataBrandIndex][0]);
        }else{
            $(cssIdBlockBottomTwo).find('.b-products__img').text(arDataBrandBottomTwo[arDataBrandIndex][0]);
        }

        $(cssIdBlockBottomTwo).find('.b-products__value').text(arDataBrandBottomTwo[arDataBrandIndex][1]);
        $(cssIdBlockBottomTwo).find('.b-products__name').text(arDataBrandBottomTwo[arDataBrandIndex][2]);
        $(cssIdBlockBottomTwo).find('.js-products-url').attr('href',arDataBrandBottomTwo[arDataBrandIndex][3]);


        $(cssIdBlockMain).swipe({
            swipeLeft:function(event,direction,distance, duration,fingerCount){
                if (fingerCount!=0) {
                    if (arDataBrandIndex==0) {
                        arDataBrandIndex = arDataBrandTopOne.length;
                    }
                    arDataBrandIndex = arDataBrandIndex - 1;
                    if ($(cssIdBlockBottom).hasClass(animationInTwo)) {
                        $(cssIdBlockBottom).removeClass(animationInTwo);
                    }else{
                        //$(cssIdBlockBottom).addClass('hidden');
                        //$(cssIdBlockBottom).addClass('visible');
                        $(cssIdBlockBottom).addClass('animated');
                        $(cssIdBlockBottom).addClass('full-visible');
                    }
                    $(cssIdBlockBottom).addClass(animationOutTwo);
                    if ($(cssIdBlockTop).hasClass(animationIn)) {
                        $(cssIdBlockTop).removeClass(animationIn);
                    }else{
                        //$(cssIdBlockTop).addClass('hidden');
                        //$(cssIdBlockTop).addClass('visible');
                        $(cssIdBlockTop).addClass('animated');
                        $(cssIdBlockTop).addClass('full-visible');
                    }
                    $(cssIdBlockTop).addClass(animationOut);
                    setTimeout(function() {
                        
                        if ($(cssIdBlockTopOne).find('.b-products__img').prop('tagName')=="IMG") {
                            $(cssIdBlockTopOne).find('.b-products__img').attr('src',arDataBrandTopOne[arDataBrandIndex][0]);
                        }else{
                            $(cssIdBlockTopOne).find('.b-products__img').text(arDataBrandTopOne[arDataBrandIndex][0]);
                        }
                        
                        $(cssIdBlockTopOne).find('.b-products__value').text(arDataBrandTopOne[arDataBrandIndex][1]);
                        $(cssIdBlockTopOne).find('.b-products__name').text(arDataBrandTopOne[arDataBrandIndex][2]);
                        $(cssIdBlockTopOne).find('.js-products-url').attr('href',arDataBrandTopOne[arDataBrandIndex][3]);
                        
                        if ($(cssIdBlockTopTwo).find('.b-products__img').prop('tagName')=="IMG") {
                            $(cssIdBlockTopTwo).find('.b-products__img').attr('src',arDataBrandTopTwo[arDataBrandIndex][0]);
                        }else{
                            $(cssIdBlockTopTwo).find('.b-products__img').text(arDataBrandTopTwo[arDataBrandIndex][0]);
                        }

                        $(cssIdBlockTopTwo).find('.b-products__value').text(arDataBrandTopTwo[arDataBrandIndex][1]);
                        $(cssIdBlockTopTwo).find('.b-products__name').text(arDataBrandTopTwo[arDataBrandIndex][2]);
                        $(cssIdBlockTopTwo).find('.js-products-url').attr('href',arDataBrandTopTwo[arDataBrandIndex][3]);

                        if ($(cssIdBlockBottomOne).find('.b-products__img').prop('tagName')=="IMG") {
                            $(cssIdBlockBottomOne).find('.b-products__img').attr('src',arDataBrandBottomOne[arDataBrandIndex][0]);
                        }else{
                            $(cssIdBlockBottomOne).find('.b-products__img').text(arDataBrandBottomOne[arDataBrandIndex][0]);
                        }
                        
                        $(cssIdBlockBottomOne).find('.b-products__value').text(arDataBrandBottomOne[arDataBrandIndex][1]);
                        $(cssIdBlockBottomOne).find('.b-products__name').text(arDataBrandBottomOne[arDataBrandIndex][2]);
                        $(cssIdBlockBottomOne).find('.js-products-url').attr('href',arDataBrandBottomOne[arDataBrandIndex][3]);
                       
                        if ($(cssIdBlockBottomTwo).find('.b-products__img').prop('tagName')=="IMG") {
                            $(cssIdBlockBottomTwo).find('.b-products__img').attr('src',arDataBrandBottomTwo[arDataBrandIndex][0]);
                        }else{
                            $(cssIdBlockBottomTwo).find('.b-products__img').text(arDataBrandBottomTwo[arDataBrandIndex][0]);
                        }

                        $(cssIdBlockBottomTwo).find('.b-products__value').text(arDataBrandBottomTwo[arDataBrandIndex][1]);
                        $(cssIdBlockBottomTwo).find('.b-products__name').text(arDataBrandBottomTwo[arDataBrandIndex][2]);
                        $(cssIdBlockBottomTwo).find('.js-products-url').attr('href',arDataBrandBottomTwo[arDataBrandIndex][3]);


                        $(cssIdBlockBottom).removeClass(animationInTwo);
                        $(cssIdBlockBottom).addClass(animationOutTwo);
                        $(cssIdBlockTop).removeClass(animationOut);
                        $(cssIdBlockTop).addClass(animationIn);
                    },animationDelay);
                }
            },
            swipeRight:function(event,direction,distance, duration,fingerCount){
                if (fingerCount!=0) {
                    arDataBrandIndex +=1;
                    if (arDataBrandIndex==arDataBrandTopOne.length) {
                        arDataBrandIndex = 0;
                    }
                    if ($(cssIdBlockBottom).hasClass(animationInTwo)) {
                        $(cssIdBlockBottom).removeClass(animationInTwo);
                    }else{
                        //$(cssIdBlockBottom).addClass('hidden');
                        //$(cssIdBlockBottom).addClass('visible');
                        $(cssIdBlockBottom).addClass('animated');
                        $(cssIdBlockBottom).addClass('full-visible');
                    }
                    $(cssIdBlockBottom).addClass(animationOutTwo);
                    if ($(cssIdBlockTop).hasClass(animationIn)) {
                        $(cssIdBlockTop).removeClass(animationIn);
                    }else{
                        //$(cssIdBlockTop).addClass('hidden');
                        //$(cssIdBlockTop).addClass('visible');
                        $(cssIdBlockTop).addClass('animated');
                        $(cssIdBlockTop).addClass('full-visible');
                    }
                    $(cssIdBlockBottom).addClass(animationOutTwo);
                    setTimeout(function() {

                        if ($(cssIdBlockTopOne).find('.b-products__img').prop('tagName')=="IMG") {
                            $(cssIdBlockTopOne).find('.b-products__img').attr('src',arDataBrandTopOne[arDataBrandIndex][0]);
                        }else{
                            $(cssIdBlockTopOne).find('.b-products__img').text(arDataBrandTopOne[arDataBrandIndex][0]);
                        }
                        
                        $(cssIdBlockTopOne).find('.b-products__value').text(arDataBrandTopOne[arDataBrandIndex][1]);
                        $(cssIdBlockTopOne).find('.b-products__name').text(arDataBrandTopOne[arDataBrandIndex][2]);
                        $(cssIdBlockTopOne).find('.js-products-url').attr('href',arDataBrandTopOne[arDataBrandIndex][3]);


                        if ($(cssIdBlockTopTwo).find('.b-products__img').prop('tagName')=="IMG") {
                            $(cssIdBlockTopTwo).find('.b-products__img').attr('src',arDataBrandTopTwo[arDataBrandIndex][0]);
                        }else{
                            $(cssIdBlockTopTwo).find('.b-products__img').text(arDataBrandTopTwo[arDataBrandIndex][0]);
                        }

                        $(cssIdBlockTopTwo).find('.b-products__value').text(arDataBrandTopTwo[arDataBrandIndex][1]);
                        $(cssIdBlockTopTwo).find('.b-products__name').text(arDataBrandTopTwo[arDataBrandIndex][2]);
                        $(cssIdBlockTopTwo).find('.js-products-url').attr('href',arDataBrandTopTwo[arDataBrandIndex][3]);


                        if ($(cssIdBlockBottomOne).find('.b-products__img').prop('tagName')=="IMG") {
                            $(cssIdBlockBottomOne).find('.b-products__img').attr('src',arDataBrandBottomOne[arDataBrandIndex][0]);
                        }else{
                            $(cssIdBlockBottomOne).find('.b-products__img').text(arDataBrandBottomOne[arDataBrandIndex][0]);
                        }
                        
                        $(cssIdBlockBottomOne).find('.b-products__value').text(arDataBrandBottomOne[arDataBrandIndex][1]);
                        $(cssIdBlockBottomOne).find('.b-products__name').text(arDataBrandBottomOne[arDataBrandIndex][2]);
                        $(cssIdBlockBottomOne).find('.js-products-url').attr('href',arDataBrandBottomOne[arDataBrandIndex][3]);


                        if ($(cssIdBlockBottomTwo).find('.b-products__img').prop('tagName')=="IMG") {
                            $(cssIdBlockBottomTwo).find('.b-products__img').attr('src',arDataBrandBottomTwo[arDataBrandIndex][0]);
                        }else{
                            $(cssIdBlockBottomTwo).find('.b-products__img').text(arDataBrandBottomTwo[arDataBrandIndex][0]);
                        }

                        $(cssIdBlockBottomTwo).find('.b-products__value').text(arDataBrandBottomTwo[arDataBrandIndex][1]);
                        $(cssIdBlockBottomTwo).find('.b-products__name').text(arDataBrandBottomTwo[arDataBrandIndex][2]);
                        $(cssIdBlockBottomTwo).find('.js-products-url').attr('href',arDataBrandBottomTwo[arDataBrandIndex][3]);

                        
                        $(cssIdBlockBottom).removeClass(animationInTwo);
                        $(cssIdBlockBottom).addClass(animationOutTwo);
                        $(cssIdBlockTop).removeClass(animationOut);
                        $(cssIdBlockTop).addClass(animationIn);
                    },animationDelay);
                }
            }
        });
    }

    clickSliderButton(cssIdBlockMain,cssIdBlockTop,cssIdBlockBottom,cssIdBlockTopOne,cssIdBlockTopTwo,cssIdBlockBottomOne,cssIdBlockBottomTwo, cssDataName, cssClassArrow, direction, arDataBrandTopOne, arDataBrandTopTwo,arDataBrandBottomOne,arDataBrandBottomTwo, arDataBrandIndex,animationOut,animationIn,animationDelay,animationOutTwo,animationInTwo){

        $(cssClassArrow).click(function() {
            if(direction == "right"){
                arDataBrandIndex +=1;
                if (arDataBrandIndex==arDataBrandTopOne.length) {
                    arDataBrandIndex = 0;
                }
            }

            if(direction == "left"){
                if (arDataBrandIndex==0) {
                    arDataBrandIndex = arDataBrandTopOne.length;
                }
                arDataBrandIndex = arDataBrandIndex - 1;
            }
            if ($(cssIdBlockBottom).hasClass(animationInTwo)) {
                $(cssIdBlockBottom).removeClass(animationInTwo);
            }else{
                //$(cssIdBlockBottom).addClass('hidden');
                //$(cssIdBlockBottom).addClass('visible');
                $(cssIdBlockBottom).addClass('animated');
                $(cssIdBlockBottom).addClass('full-visible');
            }
            $(cssIdBlockBottom).addClass(animationOutTwo);
            if ($(cssIdBlockTop).hasClass(animationIn)) {
                $(cssIdBlockTop).removeClass(animationIn);
            }else{
                //$(cssIdBlockTop).addClass('hidden');
                //$(cssIdBlockTop).addClass('visible');
                $(cssIdBlockTop).addClass('animated');
                $(cssIdBlockTop).addClass('full-visible');
            }
            $(cssIdBlockTop).addClass(animationOut);
            setTimeout(function() {
                
                if ($(cssIdBlockTopOne).find('.b-products__img').prop('tagName')=="IMG") {
                    $(cssIdBlockTopOne).find('.b-products__img').attr('src',arDataBrandTopOne[arDataBrandIndex][0]);
                }else{
                    $(cssIdBlockTopOne).find('.b-products__img').text(arDataBrandTopOne[arDataBrandIndex][0]);
                }
                
                $(cssIdBlockTopOne).find('.b-products__value').text(arDataBrandTopOne[arDataBrandIndex][1]);
                $(cssIdBlockTopOne).find('.b-products__name').text(arDataBrandTopOne[arDataBrandIndex][2]);
                $(cssIdBlockTopOne).find('.js-products-url').attr('href',arDataBrandTopOne[arDataBrandIndex][3]);


                if ($(cssIdBlockTopTwo).find('.b-products__img').prop('tagName')=="IMG") {
                    $(cssIdBlockTopTwo).find('.b-products__img').attr('src',arDataBrandTopTwo[arDataBrandIndex][0]);
                }else{
                    $(cssIdBlockTopTwo).find('.b-products__img').text(arDataBrandTopTwo[arDataBrandIndex][0]);
                }

                $(cssIdBlockTopTwo).find('.b-products__value').text(arDataBrandTopTwo[arDataBrandIndex][1]);
                $(cssIdBlockTopTwo).find('.b-products__name').text(arDataBrandTopTwo[arDataBrandIndex][2]);
                $(cssIdBlockTopTwo).find('.js-products-url').attr('href',arDataBrandTopTwo[arDataBrandIndex][3]);


                if ($(cssIdBlockBottomOne).find('.b-products__img').prop('tagName')=="IMG") {
                    $(cssIdBlockBottomOne).find('.b-products__img').attr('src',arDataBrandBottomOne[arDataBrandIndex][0]);
                }else{
                    $(cssIdBlockBottomOne).find('.b-products__img').text(arDataBrandBottomOne[arDataBrandIndex][0]);
                }
                $(cssIdBlockBottomOne).find('.b-products__value').text(arDataBrandBottomOne[arDataBrandIndex][1]);
                $(cssIdBlockBottomOne).find('.b-products__name').text(arDataBrandBottomOne[arDataBrandIndex][2]);
                $(cssIdBlockBottomOne).find('.js-products-url').attr('href',arDataBrandBottomOne[arDataBrandIndex][3]);


                if ($(cssIdBlockBottomTwo).find('.b-products__img').prop('tagName')=="IMG") {
                    $(cssIdBlockBottomTwo).find('.b-products__img').attr('src',arDataBrandBottomTwo[arDataBrandIndex][0]);
                }else{
                    $(cssIdBlockBottomTwo).find('.b-products__img').text(arDataBrandBottomTwo[arDataBrandIndex][0]);
                }

                $(cssIdBlockBottomTwo).find('.b-products__value').text(arDataBrandBottomTwo[arDataBrandIndex][1]);
                $(cssIdBlockBottomTwo).find('.b-products__name').text(arDataBrandBottomTwo[arDataBrandIndex][2]);
                $(cssIdBlockBottomTwo).find('.js-products-url').attr('href',arDataBrandBottomTwo[arDataBrandIndex][3]);
                
                $(cssIdBlockBottom).removeClass(animationOutTwo);
                $(cssIdBlockBottom).addClass(animationInTwo);
                $(cssIdBlockTop).removeClass(animationOut);
                $(cssIdBlockTop).addClass(animationIn);
            },animationDelay);
        });

    }
}

class CCottonClubSliderMainPageProductsCosmetics extends CCottonClubSliderMainPageProducts {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        let cssIdBlockMain = ".b-cosmetics";
        let cssIdBlockTop = ".b-cosmetics-top";
        let cssIdBlockBottom = ".b-cosmetics-bottom";
        let cssIdBlockTopOne = ".b-cosmetics-top__blockOne";
        let cssIdBlockTopTwo = ".b-cosmetics-top__blockTwo";
        let cssIdBlockBottomOne = ".b-cosmetics-bottom__blockOne";
        let cssIdBlockBottomTwo = ".b-cosmetics-bottom__blockTwo";
        let cssDataName = "products";
        let cssClassLeftArrow = ".b-cosmetics-buttons-left-arrow";
        let cssClassRigthArrow = ".b-cosmetics-buttons-right-arrow";
        let animationOut = 'fadeOutLeft';
        let animationIn = 'fadeInLeft';
        let animationDelay = 1000;
        let animationOutTwo = 'fadeOutRight';
        let animationInTwo = 'fadeInRight';
        this.sliderInit(cssIdBlockMain,cssIdBlockTop,cssIdBlockBottom,cssIdBlockTopOne,cssIdBlockTopTwo,cssIdBlockBottomOne,cssIdBlockBottomTwo,cssDataName,cssClassLeftArrow,cssClassRigthArrow,animationOut,animationIn,animationDelay,animationOutTwo,animationInTwo);
    }
}

class CCottonClubSliderMainPageProductsHygiene extends CCottonClubSliderMainPageProducts {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        let cssIdBlockMain = ".b-hygiene";
        let cssIdBlockTop = ".b-hygiene-top";
        let cssIdBlockBottom = ".b-hygiene-bottom";
        let cssIdBlockTopOne = ".b-hygiene-top__blockOne";
        let cssIdBlockTopTwo = ".b-hygiene-top__blockTwo";
        let cssIdBlockBottomOne = ".b-hygiene-bottom__blockOne";
        let cssIdBlockBottomTwo = ".b-hygiene-bottom__blockTwo";
        let cssDataName = "products";
        let cssClassLeftArrow = ".b-hygiene-buttons-left-arrow";
        let cssClassRigthArrow = ".b-hygiene-buttons-right-arrow";
        let animationOut = 'fadeOut';
        let animationIn = 'fadeIn';
        let animationDelay = 1000;
        let animationOutTwo = 'fadeOut';
        let animationInTwo = 'fadeIn';
        this.sliderInit(cssIdBlockMain,cssIdBlockTop,cssIdBlockBottom,cssIdBlockTopOne,cssIdBlockTopTwo,cssIdBlockBottomOne,cssIdBlockBottomTwo,cssDataName,cssClassLeftArrow,cssClassRigthArrow,animationOut,animationIn,animationDelay,animationOutTwo,animationInTwo);
    }
}

class CCottonClubSliderMainPageProductsForChildren extends CCottonClubSliderMainPageProducts {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        let cssIdBlockMain = ".b-for-children";
        let cssIdBlockTop = ".b-for-children-top";
        let cssIdBlockBottom = ".b-for-children-bottom";
        let cssIdBlockTopOne = ".b-for-children-top__blockOne";
        let cssIdBlockTopTwo = ".b-for-children-top__blockTwo";
        let cssIdBlockBottomOne = ".b-for-children-bottom__blockOne";
        let cssIdBlockBottomTwo = ".b-for-children-bottom__blockTwo";
        let cssDataName = "products";
        let cssClassLeftArrow = ".b-for-children-buttons-left-arrow";
        let cssClassRigthArrow = ".b-for-children-buttons-right-arrow";
        let animationOut = 'fadeOutRight';
        let animationIn = 'fadeInRight';
        let animationDelay = 1000;
        let animationOutTwo = 'fadeOutLeft';
        let animationInTwo = 'fadeInLeft';
        this.sliderInit(cssIdBlockMain,cssIdBlockTop,cssIdBlockBottom,cssIdBlockTopOne,cssIdBlockTopTwo,cssIdBlockBottomOne,cssIdBlockBottomTwo,cssDataName,cssClassLeftArrow,cssClassRigthArrow,animationOut,animationIn,animationDelay,animationOutTwo,animationInTwo);
    }
}

class CCottonClubSliderMainPageProducts$1 extends CCottonClubCore {

    constructor() {
        super();
    }

    sliderInit(cssIdBlockMain,cssIdBlockOne,cssIdBlockTwo,cssIdBlockThree,cssDataName,cssClassLeftArrow,cssClassRigthArrow,animationOut,animationIn,animationDelay,animationOutBack,animationInBack) {
        let direction = "right";   
        var arDataBrandOne = $(cssIdBlockOne).data(cssDataName);
        var arDataBrandTwo = $(cssIdBlockTwo).data(cssDataName);
        var arDataBrandThree = $(cssIdBlockThree).data(cssDataName);
        var arDataBrandIndex = 0;
        this.clickSliderButton(cssIdBlockMain,cssIdBlockOne,cssIdBlockTwo,cssIdBlockThree, cssDataName, cssClassRigthArrow, direction, arDataBrandOne, arDataBrandTwo,arDataBrandThree, arDataBrandIndex,animationOut,animationIn,animationDelay,animationOutBack,animationInBack);
        direction = "left";
        this.clickSliderButton(cssIdBlockMain,cssIdBlockOne,cssIdBlockTwo,cssIdBlockThree, cssDataName, cssClassLeftArrow, direction, arDataBrandOne, arDataBrandTwo,arDataBrandThree, arDataBrandIndex,animationOut,animationIn,animationDelay,animationOutBack,animationInBack);
        

        
        $(cssIdBlockOne).find('.b-news__title').text(arDataBrandOne[arDataBrandIndex][0]);
        $(cssIdBlockOne).find('.b-news__name').text(arDataBrandOne[arDataBrandIndex][1]);
        $(cssIdBlockOne).find('.b-news__date').text(arDataBrandOne[arDataBrandIndex][2]);
        $(cssIdBlockOne).find('.b-news__link').attr('href', arDataBrandOne[arDataBrandIndex][3]);
        $(cssIdBlockOne).css('background-image', 'url(' + arDataBrandOne[arDataBrandIndex][4] + ')');

        $(cssIdBlockTwo).find('.b-catalog__title').text(arDataBrandTwo[arDataBrandIndex][0]);
        $(cssIdBlockTwo).find('.b-catalog__name').text(arDataBrandTwo[arDataBrandIndex][1]);
        $(cssIdBlockTwo).find('.b-catalog__date').text(arDataBrandTwo[arDataBrandIndex][2]);
        $(cssIdBlockTwo).find('.b-news__link').attr('href', arDataBrandTwo[arDataBrandIndex][3]);
        $(cssIdBlockTwo).css('background-image', 'url(' + arDataBrandTwo[arDataBrandIndex][4] + ')');

        $(cssIdBlockThree).find('.b-news__title').text(arDataBrandThree[arDataBrandIndex][0]);
        $(cssIdBlockThree).find('.b-news__name').text(arDataBrandThree[arDataBrandIndex][1]);
        $(cssIdBlockThree).find('.b-news__date').text(arDataBrandThree[arDataBrandIndex][2]); 
        $(cssIdBlockThree).find('.b-news__link').attr('href', arDataBrandThree[arDataBrandIndex][3]); 
        $(cssIdBlockThree).css('background-image', 'url(' + arDataBrandThree[arDataBrandIndex][4] + ')');
    }

    clickSliderButton(cssIdBlockMain,cssIdBlockOne,cssIdBlockTwo,cssIdBlockThree, cssDataName, cssClassArrow, direction, arDataBrandOne, arDataBrandTwo,arDataBrandThree, arDataBrandIndex,animationOut,animationIn,animationDelay,animationOutBack,animationInBack){

        $(cssClassArrow).click(function() {
            if(direction == "right"){
                arDataBrandIndex +=1;
                if (arDataBrandIndex>arDataBrandOne.length-1) {
                    arDataBrandIndex = 0;
                }
                if ($(cssIdBlockMain).hasClass(animationInBack)) {
                    $(cssIdBlockMain).removeClass(animationInBack);
                }
                if ($(cssIdBlockMain).hasClass(animationIn)) {
                    $(cssIdBlockMain).removeClass(animationIn);
                }
                if (!$(cssIdBlockMain).hasClass('animated')){
                    //$(cssIdBlockMain).addClass('hidden');
                    //$(cssIdBlockMain).addClass('visible');
                    $(cssIdBlockMain).addClass('animated');
                    $(cssIdBlockMain).addClass('full-visible');
                }
                $(cssIdBlockMain).addClass(animationOut);
                setTimeout(function() {
                    
                     $(cssIdBlockOne).find('.b-news__title').text(arDataBrandOne[arDataBrandIndex][0]);
                            $(cssIdBlockOne).find('.b-news__name').text(arDataBrandOne[arDataBrandIndex][1]);
                            $(cssIdBlockOne).find('.b-news__date').text(arDataBrandOne[arDataBrandIndex][2]);
                            $(cssIdBlockOne).find('.b-news__link').attr('href', arDataBrandOne[arDataBrandIndex][3]); 
                            $(cssIdBlockOne).css('background-image', 'url(' + arDataBrandOne[arDataBrandIndex][4] + ')');

                            $(cssIdBlockTwo).find('.b-catalog__title').text(arDataBrandTwo[arDataBrandIndex][0]);
                            $(cssIdBlockTwo).find('.b-catalog__name').text(arDataBrandTwo[arDataBrandIndex][1]);
                            $(cssIdBlockTwo).find('.b-catalog__date').text(arDataBrandTwo[arDataBrandIndex][2]);
                            $(cssIdBlockTwo).find('.b-news__link').attr('href', arDataBrandTwo[arDataBrandIndex][3]);
                            //$(cssIdBlockTwo).find('.b-catalog__img').attr('src', arDataBrandTwo[arDataBrandIndex][4]);
                            $(cssIdBlockTwo).css('background-image', 'url(' + arDataBrandTwo[arDataBrandIndex][4] + ')');

                            $(cssIdBlockThree).find('.b-news__title').text(arDataBrandThree[arDataBrandIndex][0]);
                            $(cssIdBlockThree).find('.b-news__name').text(arDataBrandThree[arDataBrandIndex][1]);
                            $(cssIdBlockThree).find('.b-news__date').text(arDataBrandThree[arDataBrandIndex][2]); 
                            $(cssIdBlockThree).find('.b-news__link').attr('href', arDataBrandThree[arDataBrandIndex][3]); 
                            $(cssIdBlockThree).css('background-image', 'url(' + arDataBrandThree[arDataBrandIndex][4] + ')');


                            $(cssIdBlockMain).removeClass(animationOut);
                            $(cssIdBlockMain).addClass(animationIn);
                },animationDelay);
            }

            if(direction == "left"){
                arDataBrandIndex = arDataBrandIndex - 1;
                if (arDataBrandIndex<0) {
                    arDataBrandIndex = arDataBrandOne.length-1;
                }            
                if ($(cssIdBlockMain).hasClass(animationInBack)) {
                    $(cssIdBlockMain).removeClass(animationInBack);
                }
                if ($(cssIdBlockMain).hasClass(animationIn)) {
                    $(cssIdBlockMain).removeClass(animationIn);
                }
                if (!$(cssIdBlockMain).hasClass('animated')){
                    //$(cssIdBlockMain).addClass('hidden');
                   // $(cssIdBlockMain).addClass('visible');
                    $(cssIdBlockMain).addClass('animated');
                    $(cssIdBlockMain).addClass('full-visible');
                }
                $(cssIdBlockMain).addClass(animationOutBack);
                setTimeout(function() {
                    
                            $(cssIdBlockOne).find('.b-news__title').text(arDataBrandOne[arDataBrandIndex][0]);
                            $(cssIdBlockOne).find('.b-news__name').text(arDataBrandOne[arDataBrandIndex][1]);
                            $(cssIdBlockOne).find('.b-news__date').text(arDataBrandOne[arDataBrandIndex][2]);
                            $(cssIdBlockOne).find('.b-news__link').attr('href', arDataBrandOne[arDataBrandIndex][3]); 
                            $(cssIdBlockOne).css('background-image', 'url(' + arDataBrandOne[arDataBrandIndex][4] + ')');
                                   
                            $(cssIdBlockTwo).find('.b-catalog__title').text(arDataBrandTwo[arDataBrandIndex][0]);
                            $(cssIdBlockTwo).find('.b-catalog__name').text(arDataBrandTwo[arDataBrandIndex][1]);
                            $(cssIdBlockTwo).find('.b-catalog__date').text(arDataBrandTwo[arDataBrandIndex][2]);
                            $(cssIdBlockTwo).find('.b-news__link').attr('href', arDataBrandTwo[arDataBrandIndex][3]);
                            //$(cssIdBlockTwo).find('.b-catalog__img').attr('src', arDataBrandTwo[arDataBrandIndex][4]);
                            $(cssIdBlockTwo).css('background-image', 'url(' + arDataBrandTwo[arDataBrandIndex][4] + ')');

                            $(cssIdBlockThree).find('.b-news__title').text(arDataBrandThree[arDataBrandIndex][0]);
                            $(cssIdBlockThree).find('.b-news__name').text(arDataBrandThree[arDataBrandIndex][1]);
                            $(cssIdBlockThree).find('.b-news__date').text(arDataBrandThree[arDataBrandIndex][2]); 
                            $(cssIdBlockThree).find('.b-news__link').attr('href', arDataBrandThree[arDataBrandIndex][3]); 
                            $(cssIdBlockThree).css('background-image', 'url(' + arDataBrandThree[arDataBrandIndex][4] + ')');

                            $(cssIdBlockMain).removeClass(animationOutBack);
                            $(cssIdBlockMain).addClass(animationInBack);
                },animationDelay);
            }
            
        });

    }
}

class CCottonClubSliderMainPageProductsNewsBlocks extends CCottonClubSliderMainPageProducts$1 {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        let cssIdBlockMain = ".b-news-row";
        let cssIdBlockOne = ".b-news__blockOne";
        let cssIdBlockTwo = ".b-news__blockTwo";
        let cssIdBlockThree = ".b-news__blockThree";
        let cssDataName = "news";
        let cssClassLeftArrow = ".b-news-buttons-left-arrow";
        let cssClassRigthArrow = ".b-news-buttons-right-arrow";
        let animationOut = 'fadeOutLeft';
        let animationIn = 'fadeInRight';
        let animationDelay = 500;
        let animationOutBack = 'fadeOutRight';
        let animationInBack = 'fadeInLeft';
        this.sliderInit(cssIdBlockMain,cssIdBlockOne,cssIdBlockTwo,cssIdBlockThree,cssDataName,cssClassLeftArrow,cssClassRigthArrow,animationOut,animationIn,animationDelay,animationOutBack,animationInBack);
    }
}

class CCottonClubSliderHeaderArticles extends CCottonClubSlider {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        let cssIdBlockOne = ".b-header-left__item-textblock__one";
        let cssIdBlockTwo = ".b-header-left__item-textblock__two";
        let cssDataName = "articles";
        let cssClassLeftArrow = ".b-header-left__buttons-left-arrow";
        let cssClassRigthArrow = ".b-header-left__buttons-right-arrow";
        let slideByIntervalTime = 10000;
        let animationOut = 'bounceOut';
        let animationIn = 'bounceIn';
        let animationOutTwo = 'fadeOut';
        let animationInTwo = 'fadeIn';
        let animationDelay = 1000;
        let csslink = ".js-header-left__link"; 
        let backgroundImage = ".b-header"; 
        

        this.sliderInit(cssIdBlockOne,cssIdBlockTwo, cssDataName,cssClassLeftArrow,cssClassRigthArrow,slideByIntervalTime,animationOut,animationIn,animationDelay,animationInTwo,animationOutTwo, csslink, backgroundImage);


        cssIdBlockOne = ".b-header-right__item-textblock__one";
        cssIdBlockTwo = ".b-header-right__item-textblock__two";
        cssDataName = "articles";
        cssClassLeftArrow = "none";
        cssClassRigthArrow = ".b-header-right__buttons-right-arrow";
        slideByIntervalTime = 10000;
        animationOut = 'bounceOut';
        animationIn = 'bounceIn';
        animationOutTwo = 'fadeOut';
        animationInTwo = 'fadeIn';
        animationDelay = 1000;
        csslink = ".js-header-right__link"; 


        this.sliderInit(cssIdBlockOne,cssIdBlockTwo, cssDataName,cssClassLeftArrow,cssClassRigthArrow,slideByIntervalTime,animationOut,animationIn,animationDelay,animationInTwo,animationOutTwo, csslink);
    }
}

class CCottonClubSliderCompanyHistoryYears extends CCottonClubSlider {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        var historyHeadline = '.b-company-history-right-column__headline';
        var historyDescription = '.b-company-history-right-column__description';
        var swiper = new Swiper('.swiper-container', {
            direction: 'vertical',
            slidesPerView: 3,
            spaceBetween:50,
            loop:true,
            mousewheel:true,
            grabCursor: true,
        });
        swiper.on('slideChange', function () {
            $(historyHeadline).removeClass('fadeIn');
            $(historyHeadline).addClass('fadeOut');
            $(historyDescription).removeClass('fadeIn');
            $(historyDescription).addClass('fadeOut');
            setTimeout(function(){
                var arDataYear = $('.swiper-slide-active').data('year').split('&');
                $(historyHeadline).text(arDataYear[0]);
                $(historyDescription).text(arDataYear[1]);
                $(historyHeadline).removeClass('fadeOut');
                $(historyHeadline).addClass('fadeIn');
                $(historyDescription).removeClass('fadeOut');
                $(historyDescription).addClass('fadeIn');
            },1000);
        });
        
        $('.owl-carousel').owlCarousel({
            loop:true,
            slideBy:1,
            nav:true,
            navText:['',''],
            margin:10,
            dots:false,
            items:1,
        });

        $('.owl-prev').addClass('button-70x70-transparent-left');
        $('.owl-next').addClass('button-70x70-transparent-right');

    }
}

class CCottonClubTopMenu extends CCottonClubCore {

    constructor() {
        super();
    }

    execute(){
        var self = this;

        $(".js-menu-toggle").click(function() {
        	if (!$(this).hasClass('active')){
                self.showMenu();
        	}else{
                self.hideMenu();

        	}
        });

        $(".js-back-menu").click(function() {
    		self.hideMenu();
        });

        $('.b-menu-hidden-swipe').swipe({
            swipe:function(event,direction,distance, duration){
                if (direction=='left') {
                    self.showMenu();
                }
            }
        });

        $('.b-menu-mobile__body').swipe({
            swipe:function(event,direction,distance, duration){
                if (direction=='right') {
                    self.hideMenu();
                }
            }
        });

        $(".b-nav__link").click(function() {
            event.preventDefault();
            if ($("." + $(this).data("subMenu")).hasClass('active')){
                self.hideSubMenu();
            }else{
                self.hideSubMenu();   
                self.showSubMenu($(this).data("subMenu")); 
            }
        });

      
    }


    showMenu(){
        $("#header").addClass("open-menu");
        $(".b-menu-mobile").show();
        $(".b-menu-mobile__body").show();
        
        $(".js-menu-toggle").addClass("active");
    }


    hideMenu(){
        $(".js-menu-toggle").removeClass("active");
        $("#header").removeClass("open-menu");
        $(".b-menu-mobile__body").hide();

    }

    showSubMenu(selectorName){
         $("."+selectorName).show();
         $("."+selectorName).addClass("active");


    }

    hideSubMenu(){
        $(".b-menu-mobile__sub-menu").hide();
        $(".b-menu-mobile__sub-menu").removeClass("active");
    }


}

class CCottonClubParralaxEffect extends CCottonClubCore {

    constructor() {
        super();
    }

    execute(){
        var self = this;

        var marginTopAboutCompany =  $('.b-about-company').css('margin-top');

        /* Эффект парралакса блока о комании и верхнего банера*/
        $(window).bind('scroll',function(e){
            self.parallaxScroll(marginTopAboutCompany);
        });
        /* Плавное появление блока с брендами */
        $('.b-brands-slider').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeIn',
            offset: 100
        });
        /* Плавное появление блока слева */
        $('.b-cosmetics-top').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeInLeft',
            offset: 100
        });
        /* Плавное появление блока справа */
        $('.b-cosmetics-bottom').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeInRight',
            offset: 100
        });
        /* Плавное появление блока с брендами */
        $('.b-brands-slider').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeIn',
            offset: 100
        });
        /* Плавное появление блока гигиена */
        $('.b-hygiene').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeIn',
            offset: 100
        });

        /* Плавное появление блока слева */
        $('.b-for-children-bottom').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeInLeft',
            offset: 100
        });
        /* Плавное появление блока справа */
        $('.b-for-children-top').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeInRight',
            offset: 100
        });
        /* Плавное появление блока новости */
        $('.b-catalog__item').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeIn',
            offset: 100
        });

        /* Плавное появление блока новости слева */
        $('.b-news__first').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeInLeft',
            offset: 100
        });
        /* Плавное появление блока новости справа */
        $('.b-news__last').addClass("hidden").viewportChecker({
            classToAdd: 'visible animated fadeInRight',
            offset: 100
        });        

        
    }

    parallaxScroll(marginTop) {
        marginTop =  marginTop.replace('px','');
        let scrolled = $(window).scrollTop();

        //console.log(scrolled);
        if(scrolled <1000 ) {
            $('.b-header').css('margin-top',(0+(scrolled*.25))+'px');
            $('.b-header-left, .b-header-right').css('margin-top',(0-(scrolled*.5))+'px');
            $('.b-about-company').css('margin-top',(marginTop - (scrolled*.75))+'px');
        }

    }  

}

class CCottonClubSliderCatalogDetailOtherProducts extends CCottonClubSlider {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        $(function() {
            $('.owl-carousel').owlCarousel({
                loop:true,
                slideBy:1,
                nav:false,
                autoplay:true,
                autoplayTimeout:10000,
                autoplayHoverPause: true,
                responsive:{
                    1:{
                        items:1
                    },
                    480:{
                        items:2
                    },
                    768:{
                        items:3
                    },
                    1024:{
                        items:4
                    },
                    1920:{
                        items:4
                    }
                } 
            });
        });
    }
}

class CCottonClubCompanyAbout extends CCottonClubCore {

    constructor() {
        super();
    }

    /**
     * Реализация Абстрактного метода, 
     */
    execute(){
        var self = this;
        $(".js-show-sertificate").click(function() {
            if (!$(this).hasClass('active')){
                self.zoomSertificate();
            }else{
                self.unZumSertificate();

            }
        });

    }

    zoomSertificate(){     
        $(".js-show-sertificate").addClass("active");
    }


    unZumSertificate(){
        $(".js-show-sertificate").removeClass("active");
    }


}

class CCottonClubCatalogLeftMenu extends CCottonClubCore {

    constructor() {
        super();
    }

    execute(){
        var self = this;


        $( ".active_ul" ).parents("ul").addClass("active_ul");  
        $( ".bx_sitemap_ul" ).removeClass("active_ul");
        $( ".active_ul" ).show(function() { 
            if($(this).hasClass("level_3")){
                self.showActiveMenu($(this).parent("li").find(".js-catalog-menu"));
                $(this).parent("li").parent("li").children().children(".js-catalog-menu");
            }
            if($(this).hasClass("level_2")){
              $(this).prev().find(".js-catalog-menu").addClass("fa-angle-up");
              $(this).prev().find(".js-catalog-menu").removeClass("fa-angle-down");
            }
        });


        $(".js-catalog-menu").click(function() {
        	if ($(this).hasClass('fa-angle-up') || $(this).hasClass('b-catalog-list__icon-level-2-close')){
                self.hideMenu($(this));

        	}else{
                self.showMenu($(this));
        	}

        });


        $(".js-catalog-filter-list").click(function() {
            $(".b-catalog-list__product-row").find(".b-catalog-list__product-item").each(function(el){
                $(this).addClass("row").addClass("b-catalog-list--modificator-view-list").removeClass("col-sm-4");
                $(this).find(".b-catalog-list__product-img-block").addClass("col-sm-4");
                $(this).find(".b-catalog-list__product-description").addClass("col-sm-8");
            });
        });


        $(".js-catalog-filter-plitka").click(function() {
            $(".b-catalog-list__product-row").find(".b-catalog-list__product-item").each(function(el){
                $(this).removeClass("row").removeClass("b-catalog-list--modificator-view-list").addClass("col-sm-4");
                $(this).find(".b-catalog-list__product-img-block").removeClass("col-sm-4");
                $(this).find(".b-catalog-list__product-description").removeClass("col-sm-8");
            });
        });

        $(".js-show-catalog-menu").click(function() {
          if ($(".left_menu").hasClass('d-none')){
            $(".left_menu").addClass("d-block").removeClass("d-none");
            $(".js-show-parameters").hide();
            $(".js-hide-parameters").show();
          }else{
            $(".left_menu").addClass("d-none").removeClass("d-block");
            $(".js-hide-parameters").hide();
            $(".js-show-parameters").show();
          } 


        });


        
      
    }


    showMenu(selector){
        var blockID = selector.data("blockid");
        var level = selector.data("child-level");
        //console.log(blockID);
        $('#'+blockID).find('.'+level).show();
        if(level == "level_3"){
          selector.addClass("b-catalog-list__icon-level-2-close");
          selector.removeClass("b-catalog-list__icon-level-2-open");
          $('#'+blockID).css("background-color", "#f6fbfe");
          $('#'+blockID).find("ul li").css("background-color", "#f6fbfe");
        }else{
          selector.addClass("fa-angle-up");
          selector.removeClass("fa-angle-down");
        } 
    }

    showActiveMenu(selector){
        var blockID = selector.data("blockid");
        var level = selector.data("child-level");
        $('#'+blockID).find('.'+level).show();

        if(level == "level_3"){
          selector.addClass("b-catalog-list__icon-level-2-close");
          selector.removeClass("b-catalog-list__icon-level-2-open");
          $('#'+blockID).css("background-color", "#f6fbfe");
          $('#'+blockID).find("ul li").css("background-color", "#f6fbfe");
        }else{
          selector.addClass("fa-angle-up");
          selector.removeClass("fa-angle-down");
        }    



    }


    hideMenu(selector){
        var blockID = selector.data("blockid");
        var level = selector.data("child-level");
        //console.log(blockID);
        $('#'+blockID).find('.'+level).hide();
        if(level == "level_3"){
          selector.addClass("b-catalog-list__icon-level-2-open");
          selector.removeClass("b-catalog-list__icon-level-2-close");
            $('#'+blockID).css("background-color", "#fff");
            $('#'+blockID).find("ul li").css("background-color", "#fff");
        }else{
          selector.addClass("fa-angle-down");
          selector.removeClass("fa-angle-up");
        }

        // $(".js-menu-toggle").removeClass("active");
        // $("#header").removeClass("open-menu");
        // $(".b-menu-mobile__body").hide();

    }


}

class CCottonClubMainPageVideo extends CCottonClubCore {

    constructor() {
        super();
    }

    execute(){
        $(".js-video").click(function() {

            var video = "https://www.youtube.com/embed/" + $(this).data("block");
            $(".qa-widget-player").attr("src","");
            $(".qa-widget-player").attr("src",video);        

        });
      
    }

}

class CCottonClubApp {
    constructor() {
            if (this.detectSlider() === true) {
                this.initSlider();
            }

            if (this.detectSliderBrands() === true) {
                this.initSliderBrands();
            }

            if (this.detectSliderMainProductsCosmetics() === true) {
                this.initSliderMainProductsCosmetics();
            }

            if (this.detectSliderMainProductsHygiene() === true) {
                this.initSliderMainProductsHygiene();
            }

            if (this.detectSliderMainProductsForChildren() === true) {
                this.initSliderMainProductsForChildren();
            }

            if (this.detectSliderMainNewsBlocks() === true) {
                this.initSliderMainNewsBlocks();
            }            

            if (this.detectSliderCompanyHistoryYears() === true) {
                this.initSliderCompanyHistoryYears();
            } 

            if ($('.js-catalog-detial-slider').length > 0) {
                this.initSliderCatalogDetail();
            }

            if ($('.b-header-left__item').length > 0) {
                this.initSliderArticles();
            }

            if ($('.js-catalog-menu').length > 0) {
                this.initCatalogMenu();
            }
           

            this.initHeader();
            
            if ($('.b-main-page').length > 0) {
                this.initMainPage();
            }
            if ($('.b-company-about').length > 0) {
                this.initCompanyAbout();
            }
        }

    detectSlider() {
        return $('#b-content-row-textblock').length > 0;
    }

    detectSliderBrands() {
        return $('.js-brands-swipe').length > 0;
    }


    detectSliderMainProductsCosmetics() {
        return $('.b-cosmetics').length > 0;
    }

    detectSliderMainProductsHygiene() {
        return $('.b-hygiene').length > 0;
    }

    detectSliderMainProductsForChildren() {
        return $('.b-for-children').length > 0;
    }

    detectSliderMainNewsBlocks() {
        return $('.b-news-row').length > 0;
    }

    detectSliderCompanyHistoryYears() {
        return $('.b-company-history-center-column').length > 0;
    }

    initHeader() {
        this.initTopMenu();
        var scrolled;
        window.onscroll = function() {
            scrolled = window.pageYOffset || document.documentElement.scrollTop;
            if(scrolled > 40){
                $(".b-header-background").css({"background-color": "rgba(43, 43, 43, 0.47)"});
                $(".b-topline-v3__link").css({"color": "#fff"});

            }
            if(40 > scrolled){
                $(".b-header-background").css({"background-color": "rgba(255, 255, 255, 0.77)"});
                $(".b-topline-v3__link").css({"color": "#555555"});   

            }

        };
    }
    initMainPage(){
        new CCottonClubMainPageVideo();
        //this.initParralaxEffect();
    }

    initSlider() {
        const $sliderBlock = $('#b-content-row-textblock');
        if ($sliderBlock.length === 0) return;
        new CCottonClubSliderMainPageOurBrands($sliderBlock);
    }
    initSliderBrands() {
        const $sliderBlockBrands = $('.js-brands-swipe');
        if ($sliderBlockBrands.length === 0) return;
        new CCottonClubSliderMainPageBrands($sliderBlockBrands);
    }

    initSliderCatalogDetail() {
        const $sliderBlockBrands = $('.js-catalog-detial-slider');
        if ($sliderBlockBrands.length === 0) return;
        new CCottonClubSliderCatalogDetailOtherProducts($sliderBlockBrands);
    }

    initSliderMainProductsCosmetics() {
        const $sliderBlockProductsCosmetics = $('.b-cosmetics');
        if ($sliderBlockProductsCosmetics.length === 0) return;
        new CCottonClubSliderMainPageProductsCosmetics($sliderBlockProductsCosmetics);
    }
    initSliderMainProductsHygiene() {
        const $sliderBlockProductsHygiene = $('.b-hygiene');
        if ($sliderBlockProductsHygiene.length === 0) return;
        new CCottonClubSliderMainPageProductsHygiene($sliderBlockProductsHygiene);
    }
    initSliderMainProductsForChildren() {
        const $sliderBlockProductsForChildren = $('.b-for-children');
        if ($sliderBlockProductsForChildren.length === 0) return;
        new CCottonClubSliderMainPageProductsForChildren($sliderBlockProductsForChildren);
    }
    initSliderMainNewsBlocks() {
        const $sliderBlockNewsBlocks = $('.b-news-row');
        if ($sliderBlockNewsBlocks.length === 0) return;
        new CCottonClubSliderMainPageProductsNewsBlocks($sliderBlockNewsBlocks);
    }
    initSliderArticles(){
        const $sliderBlockArticles = $('.b-header-left__item');
        if ($sliderBlockArticles.length === 0) return;
        new CCottonClubSliderHeaderArticles($sliderBlockArticles);
    }    
    initSliderCompanyHistoryYears(){
        const $sliderCompanyHistoryYears = $('.swiper-container');
        if ($sliderCompanyHistoryYears.length === 0) return;
        new CCottonClubSliderCompanyHistoryYears($sliderCompanyHistoryYears);
    }
    initTopMenu() {
        new CCottonClubTopMenu();
    }

    initCatalogMenu() {
        new CCottonClubCatalogLeftMenu();   
    }

    initParralaxEffect(){
        new CCottonClubParralaxEffect();

    }

    initCompanyAbout(){
        new CCottonClubCompanyAbout();
    }

}

new CCottonClubApp();
